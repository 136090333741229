var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"kiosks-items-table"},[_c('el-table',{staticClass:"table-responsive table-flush table-bordered",attrs:{"header-row-class-name":"thead-light","data":_vm.devices}},[_c('el-table-column',{attrs:{"label":_vm.translate('NAME'),"prop":"attributes.label","min-width":"140px"}}),_c('el-table-column',{attrs:{"label":_vm.translate('Location'),"min-width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{staticStyle:{"width":"12px","margin-right":"10px"},attrs:{"src":require(("@/assets/images/" + (row.attributes.status) + ".png"))}}),_vm._v(" "+_vm._s(row.attributes['location-name'])+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Battery'),"min-width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.attributes['battery-level'] > 0)?_c('span',[_vm._v(_vm._s(row.attributes['battery-level'])+"%")]):_vm._e(),(row.attributes['battery-level'] > 0)?_c('base-progress',{attrs:{"type":row.attributes['battery-level'] < 20 ? 'danger' : 'success',"value":row.attributes['battery-level'],"size":"md","height":10}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Storage Used'),"min-width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.attributes['device-capacity'] > 0)?_c('span',[_vm._v(_vm._s(+row.attributes['device-capacity'] - +row.attributes['available-device-capacity'])+"GB")]):_vm._e(),(row.attributes['device-capacity'] > 0)?_c('base-progress',{attrs:{"type":_vm.storageUsedStatus(row),"value":+row.attributes['device-capacity'] - +row.attributes['available-device-capacity'],"size":"lg","height":10}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Device'),"min-width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.attributes['product-name'])?_c('span',[_c('img',{staticStyle:{"width":"16px"},attrs:{"src":require(("@/assets/images/icons/" + (row.attributes['os-name'].toLowerCase()) + ".png")),"alt":((row.attributes['product-name']) + "-icon")}}),_vm._v(" "+_vm._s(row.attributes['product-name'])+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('OS Version'),"min-width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.attributes['os-version']))]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Payment Device'),"min-width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.attributes['ip-address'])+" - "+_vm._s(row.attributes['terminal-id']))]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Last Seen'),"min-width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.lastSeenAt(row)))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('ACTION'),"min-width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-button-group',[_c('base-button',{attrs:{"icon":"","type":"primary","size":"sm"},on:{"click":function($event){return _vm.$emit('edit-clicked', row)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-pencil-alt"})])]),(row.attributes['is-enrolled'])?_c('base-button',{attrs:{"icon":"","type":"success","size":"sm"},on:{"click":function($event){return _vm.$emit('refresh-mdm-clicked', row)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-sync-alt"})])]):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }