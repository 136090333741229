





























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Device as DeviceModel } from "@/models";
import { translations, notificationAlerts } from "@/mixins";
import moment from "moment";
import { Table, TableColumn } from "element-ui";

@Component({
  components: {
    KiosksItem: () => import("./KiosksItem"),
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  mixins: [translations, notificationAlerts],
})
export default class ModGroup extends Vue {
  @Prop() devices!: Array<DeviceModel>;

  private updateItem(item: DeviceModel) {}

  private statusImage(item: DeviceModel) {
    return require(`@/assets/images/${item.attributes.status}.png`);
  }

  private lastSeenAt(item: DeviceModel) {
    if (!item.attributes["last-seen-at-utc"]) {
      return "Never";
    }

    return moment
      .utc(item.attributes["last-seen-at-utc"], "YYYY-MM-D hh:mm:ss")
      .locale("en-us")
      .fromNow();
  }

  private storageUsedStatus(item: DeviceModel) {
    let attributes = item.attributes;
    let leftCapacity =
      attributes["device-capacity"] - attributes["available-device-capacity"];

    return Math.round((attributes["device-capacity"] / leftCapacity) * 100) > 80
      ? "danger"
      : "success";
  }
}
